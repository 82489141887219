"use strict";

/**
 * Склонение существительных после числительных
 *
 * declension(3, ['дерево', 'дерева', 'деревьев']);
 *
 * @param {number} n
 * @param {string[]} forms
 * @return {string}
 */
export default function (n, forms) {
  var index =
    n % 10 == 1 && n % 100 != 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2;
  return forms[index];
}
