import "./apps-search-form.scss";

import $ from "jquery";
import url from "url";
import cookies from "../../lib/est-cookies";
import debounce from "lodash/debounce";
import declension from "../../lib/declension";
import translate from "../../lib/translate";
import scrollto from "../../lib/scrollto";
import { language } from "../../lib/language";

const assign = Object.assign;
const lang_prefix = "/" + language;

const slug_ukraine = "ukraine";
const slug_kupit = "kupit";
const slug_flats = "kvartiru";

translate.add({
  "Выберите район": "Оберіть район",
  "Украина": "Україна",
  "Выбран": "Вибран",
  "Выбрано": "Вибрано",
  "района": "району",
  "районов": "районів",
  "объявление": "оголошення",
  "объявления": "оголошення",
  "объявлений": "оголошень",
  "нет объявлений": "немає оголошень",
  "есть": "є",
  "недвижимость": "нерухомість",
  "всю недвижимость": "всю нерухомість",
  "сдают посуточно": "здають подобово",
  "посуточно": "подобово",
  "земельный участок": "зімельну ділянку",
});

/**
 * wtf ?
 */
var RedwawSavedSearchDropdown = function () {
  $.ajax({
    url: $("#find-saver").data("load-url"),
    dataType: "jsonp",
    data: {
      sf_format: "jsonp",
    },
    success: function (data) {
      $(".find-count").html(data.count);
      var names = "";
      var counts = "";
      var namesMask =
        '<div><a class="find-name-link-name" data-id="%id%" href="%href%">%name%</a><span class="grey date">%date%</span><a class="del-close-small" data-id="%id%" href="/kabinet/objavlenija/search_requests/remove/%id%/"> </a></div>';
      var countsMask =
        '<div><span data-id="%id%" class="count">%count%</span><span class="grey">объявления</span></div>';
      if (data.count > 0) {
        if (data.count > 5) {
          $(".find-view-all").removeClass("hidden");
        } else {
          $(".find-view-all").addClass("hidden");
        }
        for (var o in data.e) {
          $("#find-count-toggle").parent().removeClass("hidden");
          names =
            namesMask
              .replace(/%id%/g, o)
              .replace("%date%", data.e[o].dt)
              .replace("%href%", data.e[o].url)
              .replace("%name%", data.e[o].name) + names;
          counts =
            countsMask
              .replace(/%id%/g, o)
              .replace("%count%", data.e[o].ResultCount) + counts;
        }
        $(".finder-name").html(names);
        $(".finder-count").html(counts);
        FindNameLinkHover();
      } else {
        $("#find-count-toggle").parent().addClass("hidden");
        $(".finder-name").html("");
        $(".finder-count").html("");
      }
    },
  });
};

$(document).on("click", ".del-close-small", function (e) {
  var self = this;
  $.ajax({
    dataType: "jsonp",
    data: {
      sf_format: "jsonp",
    },
    url: $(self).attr("href"),
    success: function () {
      RedwawSavedSearchDropdown();
    },
  });
  e.preventDefault();
});

/**
 * wtf ?
 */
var FindNameLinkHover = function () {
  $(".find-name-link-name")
    .parent("div")
    .on("mouseenter", function () {
      var id = $(this).children(".find-name-link-name").data("id");
      $(".find-name-link-name[data-id=" + id + "]").addClass("hover");
      $(".del-close-small[data-id=" + id + "]").addClass("hover");
      $(".count[data-id=" + id + "]").addClass("hover");
    });
  $(".find-name-link-name")
    .parent("div")
    .on("mouseleave", function () {
      var id = $(this).children(".find-name-link-name").data("id");
      $(".find-name-link-name[data-id=" + id + "]").removeClass("hover");
      $(".del-close-small[data-id=" + id + "]").removeClass("hover");
      $(".count[data-id=" + id + "]").removeClass("hover");
    });
};

window.init_commercial_search = function (options) {
  var tt_info = options.tt_info || {};
  var wrapper = $(".apps-search-form");
  var form_simple = $(".apps-search-form__tab--simple");
  var form_advanced = $(".apps-search-form__tab--normal");

  // ------------------------------------------------------------------------

  const simple_where = form_simple.find('select[name="where"]');

  simple_where.on("change", (e) => {
    const loc = url.parse(location.href, true);
    const action = url.parse(wrapper.attr("data-action"), true);
    const { relation, visible } = loc.query;
    const where = e.target.value;
    assign(action.query, { where });
    visible && assign(action.query, { visible });
    relation && assign(action.query, { relation });
    delete action.search;
    delete action.query.lj;
    const href = url.format(action);
    location.assign(href);
  });

  // ------------------------------------------------------------------------

  var button_submit = form_advanced.find(".button-submit");
  var param_free_planning = form_advanced.find(
    ".apps-search-form__widget-is-free-planning"
  );
  var param_square = form_advanced.find(".apps-search-form__widget-square");
  var param_square_land = form_advanced.find(
    ".apps-search-form__widget-square-land"
  );
  var param_square_living = form_advanced.find(
    ".apps-search-form__widget-square-living"
  );
  const param_floor_restr = form_advanced.find(
    ".apps-search-form__widget-floor-restrictions"
  );
  const param_floor = form_advanced.find(".apps-search-form__widget-floor");
  const param_room = form_advanced.find(".apps-search-form__widget-room");
  const param_rajons = form_advanced.find(".apps-search-form__widget-rajons");
  const param_where = form_advanced.find(".apps-search-form__widget-where");
  const param_price = form_advanced.find(".apps-search-form__widget-price");
  const param_what = form_advanced.find(".apps-search-form__widget-what");
  const toggler_currency = param_price.find(".est-dropdown-toggle");
  const toggler_rajons = param_rajons.find(".est-dropdown-toggle");
  const toggler_where = param_where.find(".est-dropdown-toggle");
  const toggler_what = param_what.find(".est-dropdown-toggle");
  const inputs = form_advanced.find(":input[name]");
  const input_request_target = inputs.filter('[name="request_target"]');
  const input_realty_type = inputs.filter('[name="realty_type"]');
  const input_where_text = inputs.filter('[name="where_name"]');
  const input_where_slug = inputs.filter('[name="where"]');
  const input_search = inputs.filter('[name="search"]');
  const input_price_for = inputs.filter('[name="price_for"]');
  const input_floor = inputs.filter('[name="floor[]"]');
  var rajons_list = param_rajons.find(".apps-search-form__rj-lj-lm");
  var param_c_type = form_advanced.find(".apps-search-form__widget-c-type");

  var widget_polygon_id = inputs.filter('[name="polygon_id"]');
  var jMap = $(".est-map");

  var state_base = {};
  var state_init = {};
  var state_prev = {};

  var do_submit = function () {
    var params = get_params({
      "find-name": null,
      "where_name": null,
      "polygon_id": null,
      "submitted": 1,
    });

    // при выполнении поиска ставим валюту отображения такой же как валюту поиска
    if (params.currency) {
      params.currency = params.currency.toUpperCase();
      params.price_currency = params.currency;
    }

    if (
      (params.price_from || params.price_till) &&
      params.currency &&
      params.currency == "UAH"
    ) {
      delete params.currency;
    }

    if (!(params.price_from || params.price_till)) {
      delete params.currency;
    }

    var param = get_params(
      {
        sf_format: "json",
        where_name: null,
      },
      params || {}
    );

    if ($("#is_novostrojka").prop("checked")) {
      wrapper.attr("data-action", wrapper.attr("data-action-novostrojka"));
    }

    var url = wrapper.attr("data-action");

    if (sf_module == "commercial" || "homepage" == sf_module) {
      if (
        param.request_target &&
        param.realty_type &&
        !$("#is_novostrojka").prop("checked")
      ) {
        url += param.request_target + "-" + param.realty_type + "/";
      }
      delete param["request_target"];
      delete param["realty_type"];
    }

    $(document.documentElement).addClass("unload");

    location.href =
      url + "?" + $.param(params) + (params.map ? "#map-wrapper" : "");
  };

  var get_params = function () {
    var values = {};
    var clean = {};

    assign(values, form_advanced.find(":input[name]").serializeObject());

    $.each(arguments, function (i, argument) {
      assign(values, argument || {});
    });

    $.each(values, function (key, val) {
      var value = $.map($.isArray(val) ? val : [val], function (v, i) {
        return (v != void 0 && v !== null && v !== "") || v === 0 ? v : null;
      });

      if (value.length == 1) {
        clean[key] = value[0];
      }

      if (value.length > 1) {
        clean[key] = value;
      }
    });

    // чистим от дополнительных параметров, которые не относятся к выбранному типу недвижимости
    var request_target = clean.request_target || null;
    var realty_type = clean.realty_type || null;
    var target_type = request_target + "_" + realty_type;
    var fields = target_type in tt_info ? tt_info[target_type] : [];
    var $fields = $(fields);

    let dependencies = {
      floor: ["floor"],
      floor_from: ["floor"],
      floor_till: ["floor"],
      room: ["amount-of-rooms", "amount-of-bedrooms"],
      is_free_planning: ["is-free-planning"],
      square_land_from: ["square-land-ar", "square-land-ga"],
      square_land_till: ["square-land-ar", "square-land-ga"],
      square_living_from: ["living-square"],
      square_living_till: ["living-square"],
      price_for: ["cost-for-meter", "cost-for-ar", "cost-for-ga"],
      c_type: ["commercial-type"],
    };

    if (wrapper.attr("data-show-square") !== "1") {
      dependencies.square_from = [
        "square",
        "square-halls",
        "square-rooms",
        "square-hotel-rooms",
        "square-prodaction",
        "square-warehouse",
      ];
      dependencies.square_till = [
        "square",
        "square-halls",
        "square-rooms",
        "square-hotel-rooms",
        "square-prodaction",
        "square-warehouse",
      ];
    }

    $.each(dependencies, function (param, aliases) {
      if (!$fields.filter($(aliases)).get().length) {
        delete clean[param];
      }
    });

    return clean;
  };

  var get_counts_xhr = null;
  var get_counts = debounce(function (params, callback) {
    var param = get_params(
      {
        sf_format: "jsonp",
        where_name: null,
      },
      params || {}
    );
    var url = wrapper.attr("data-action");

    if (sf_module == "commercial" || sf_module == "homepage") {
      if (param.request_target && param.realty_type) {
        url += param.request_target + "-" + param.realty_type + "/";
      }
      delete param["request_target"];
      delete param["realty_type"];
    }

    get_counts_xhr && get_counts_xhr.abort();
    get_counts_xhr = $.ajax({
      url: url,
      dataType: "jsonp",
      data: param,
      complete: function () {
        get_counts_xhr = null;
      },
      success: function (data) {
        callback.call(null, data);
      },
    });
  }, 100);

  var rajons_show_selected = function () {
    var tmp_rj_lj = [];
    var tmp_lm = [];

    form_advanced
      .find(".apps-search-form__rj-list input:checked")
      .each(function () {
        tmp_rj_lj.push(
          '<span data-name="' +
            this.name +
            '" data-value="' +
            this.value +
            '"><label>' +
            $(this).next("label, span").text() +
            "</label><del>&nbsp;</del></span>"
        );
      });

    form_advanced
      .find(".apps-search-form__lj-list input:checked")
      .each(function () {
        tmp_rj_lj.push(
          '<span data-name="' +
            this.name +
            '" data-value="' +
            this.value +
            '"><label>' +
            $(this).next("label, span").text() +
            "</label><del>&nbsp;</del></span>"
        );
      });

    form_advanced
      .find(".apps-search-form__lm-list input:checked")
      .each(function () {
        tmp_lm.push(
          '<span data-name="' +
            this.name +
            '" data-value="' +
            this.value +
            '"><label>' +
            $(this).next("label, span").text() +
            "</label><del>&nbsp;</del></span>"
        );
      });

    form_advanced
      .find(".apps-search-form__rj-lj-selected")
      .html(tmp_rj_lj.join(" "))
      .toggleClass(
        "apps-search-form__rj-lj-selected--empty",
        !tmp_rj_lj.length
      );

    form_advanced
      .find(".apps-search-form__lm-selected")
      .html(tmp_lm.join(" "))
      .toggleClass("apps-search-form__lm-selected--empty", !tmp_lm.length);
  };

  // ------------------------------------------------------------------------

  var rajon_change_button_caption = function () {
    var rj = param_rajons.find(".apps-search-form__rj-list input");
    var lj = param_rajons.find(".apps-search-form__lj-list input");
    var lm = param_rajons.find(".apps-search-form__lm-list input");
    var rj_selected = form_advanced.find(
      ".apps-search-form__rj-list input:checked"
    ).length;
    var lj_selected = form_advanced.find(
      ".apps-search-form__lj-list input:checked"
    ).length;
    var cnt = rj_selected + lj_selected;

    cnt || toggler_rajons.text(translate("Выберите район"));
    cnt &&
      toggler_rajons.text(
        [
          declension(cnt, ["Выбран", "Выбрано", "Выбрано"]),
          cnt,
          declension(cnt, ["район", "района", "районов"]),
        ].join(" ")
      );

    if (rj.length || lj.length) {
      toggler_rajons.removeClass("disabled");
      param_rajons.parent().show();
    } else {
      toggler_rajons.addClass("disabled");
      param_rajons.parent().hide();
    }
  };

  // ------------------------------------------------------------------------
  // переключение расширенных параметров

  wrapper.on(
    "select",
    ".apps-search-form__level-switcher .est-switcher-panel__item",
    function (e) {
      const key = $(this).attr("data-key");
      const namespace = wrapper.attr("data-namespace");
      const [type, level] = key.split("-");

      const is_normal = type === "normal";
      const selector_simple = ".apps-search-form__tab--simple";
      const selector_normal = ".apps-search-form__tab--normal";
      const class_selected = "apps-search-form__tab--selected";

      wrapper.find(selector_simple).toggleClass(class_selected, !is_normal);
      wrapper.find(selector_normal).toggleClass(class_selected, is_normal);
      wrapper.attr("data-level", level);

      cookies.set("apps-search-form-" + namespace, type + "-" + level);
    }
  );

  // ------------------------------------------------------------------------

  // запоминаем состояние формы
  (function () {
    let default_currency;
    let state_temp;

    // если нет объектов, выключаем submit
    if (button_submit.attr("data-count") > 0) {
      button_submit.removeClass("disabled");
    } else {
      button_submit.addClass("disabled");
    }

    default_currency =
      $("body")
        .attr("class")
        .match(/is-currency-([a-z]{3})/)[1] || "UAH";
    default_currency = default_currency.toUpperCase();

    state_temp = assign({}, form_advanced.find(":input[name]").values());

    state_base = assign({}, state_temp);
    state_prev = assign({}, state_temp);
    state_init = assign({}, state_temp, {
      "sort": [""],
      "record_id": [""],
      "request_target": [slug_kupit],
      "realty_type": [slug_flats],
      "where_name": [translate("Украина")],
      "where": [slug_ukraine],
      "rj[]": [],
      "lj[]": [],
      "lm[]": [],
      "price_from": [""],
      "price_till": [""],
      "currency": [default_currency],
      "price_for": [""],
      "square_from": [""],
      "square_till": [""],
      "square_land_from": [""],
      "square_land_till": [""],
      "square_living_from": [""],
      "square_living_till": [""],
      "room[]": [],
      "floor[]": [],
      "floor_from": [""],
      "floor_till": [""],
      "is_free_planning": [],
      "is_novostrojka": [],
      "is_profi": [],
      "is_owner": [],
      "is_video": [],
      "is_foto": [],
      "dogovor": [],
      "search": [""],
      "polygon_id": [""],
      "c-type[]": [],
    });

    const apps_switcher_item = $(
      '.vcard-menu .est-switcher-panel [data-key="apps"]'
    );
    if (apps_switcher_item.length) {
      let default_where =
        apps_switcher_item.attr("data-where") ||
        form_advanced.find('input[name="where"]:first').attr("value");
      let default_request_target = apps_switcher_item.attr(
        "data-request-target"
      );
      let default_realty_type = apps_switcher_item.attr("data-realty-type");

      assign(state_init, {
        where_name: [""],
        where: [default_where],
        request_target:
          typeof default_request_target !== "undefined" &&
          default_request_target !== false
            ? [default_request_target]
            : [slug_kupit],
        realty_type:
          typeof default_realty_type !== "undefined" &&
          default_realty_type !== false
            ? [default_realty_type]
            : [""],
      });
    }
  })();

  var is_equal_states = function (state1, state2) {
    state1["rj[]"] = "rj[]" in state1 ? state1["rj[]"] : [];
    state1["lj[]"] = "lj[]" in state1 ? state1["lj[]"] : [];
    state1["lm[]"] = "lm[]" in state1 ? state1["lm[]"] : [];
    state2["rj[]"] = "rj[]" in state2 ? state2["rj[]"] : [];
    state2["lj[]"] = "lj[]" in state2 ? state2["lj[]"] : [];
    state2["lm[]"] = "lm[]" in state2 ? state2["lm[]"] : [];

    return $.equals(state1, state2);
  };

  // ------------------------------------------------------------------------
  // suggest

  input_search.autocomplete({
    minLength: 2,
    source: function (request, response) {
      var params = get_params();
      var data = assign({}, params, {
        term: request.term,
      });

      $.ajax({
        url: input_search.attr("data-autocomplete-url"),
        data: data,
        dataType: "jsonp",
        error: function () {
          response([]);
        },
        success: function (data) {
          response(
            $.map(data, function (label, value) {
              return {
                value: value,
                label: label,
              };
            })
          );
        },
      });
    },
    create: function (event, ui) {
      var widget = $(this);

      // fix ie autoopen bug
      widget.data("ui-autocomplete").term = input_where_text.val();
      // modify render
      widget.data("ui-autocomplete")._renderItem = function (ul, item) {
        return $("<li></li>")
          .data("ui-autocomplete-item", item)
          .append($("<a></a>").html(item.label))
          .appendTo(ul);
      };
    },
  });

  input_search.on("click", function () {
    input_search.autocomplete("search");
  });
  input_search.on("keyup", function (event) {
    if (event.which == 13) {
      input_search.blur().focus();
    }
  });

  // ------------------------------------------------------------------------
  // выбиралка местоположений

  input_where_text.autocomplete({
    minLength: 0,
    source: `${lang_prefix}/autocomplete/where/?need[]=countries&need[]=regions&need[]=localities&sf_format=jsonp&callback=?`,
    create: function (event, ui) {
      var widget = $(event.target);

      // fix ie autoopen bug
      widget.data("ui-autocomplete").term = input_where_text.val();
      // styling
      widget
        .data("ui-autocomplete")
        .menu.element.addClass("est-autocomplete-where");
    },
  });

  // autoopen on click
  input_where_text.on("click", function () {
    input_where_text.val([]);
    input_where_text.select();
    input_where_text.removeAttr("title");
    input_where_text.autocomplete("search");
  });
  input_where_text.on("drop paste contextmenu", false);
  input_where_text.on("keydown", function (event) {
    if ((event.ctrlKey || event.altKey || event.metaKey) && event.which == 86) {
      return false;
    }
  });

  // не показываем выпадалку населенных пунктов если их меньше 2
  if (param_where.find(":radio").length < 2) {
    param_where.find(":radio").prop("checked", true);
    toggler_where.addClass("disabled");
    toggler_where.on("dropdown-activate", function () {
      return false;
    });
  }

  // ------------------------------------------------------------------------
  // подгрузка районов в глобальной форме

  input_where_text.on("autocompleteselect autocompletechange", function (
    event,
    ui
  ) {
    if (ui.item) {
      input_where_text.attr("title", ui.item.value);
      input_where_text.values(
        {
          where_name: ui.item.value,
        },
        true
      );
      input_where_slug.values(
        {
          where: ui.item.where,
        },
        true
      );
    } else {
      input_where_text.attr("title", input_where_text.val());
      input_where_slug.values({}, true);
    }
    rajons_list.empty();
    form_advanced.trigger("render");
  });

  // ------------------------------------------------------------------------
  // районы и ориентиры

  param_rajons.on("click", ".est-dropdown-toggle", function () {
    return !toggler_rajons.is(".disabled");
  });

  param_rajons.on("change", "input", function (event) {
    if (
      param_rajons.find(
        ".apps-search-form__rj-list input:checked, .apps-search-form__lj-list input:checked"
      ).length
    ) {
      param_rajons
        .find(".apps-search-form__lm-list input:checked")
        .prop("checked", false);
    }

    rajons_show_selected();
  });

  form_advanced.on(
    "click",
    ".apps-search-form__rj-lj-selected del, .apps-search-form__lm-selected del",
    function () {
      var item = $(this).closest("span");
      var name = item.attr("data-name");
      var value = item.attr("data-value");
      var input = param_rajons.find(
        'input[name="' + name + '"][value="' + value + '"]'
      );

      input.prop("checked", false).trigger("change");
    }
  );

  // ------------------------------------------------------------------------
  // выбиралка операций и типов недвижимости

  param_what.on("dropdown-activated", function () {
    const checked_request_target = input_request_target.filter(":checked");
    const realty_groups = param_what.find(".realty-type-group");
    const is_posutochno = checked_request_target.val() === "snjat-posutochno";

    $(".cell-request-target li .realty-type-groups").hide();

    false &&
      $(checked_request_target)
        .closest("li")
        .find(".realty-type-groups")
        .slideDown();

    if (is_posutochno) {
      realty_groups.removeClass("hidden");
      realty_groups
        .filter(
          ':not([data-slug="zhiluyu-nedvizhimost"], [data-slug="vsyu-nedvizhimost"])'
        )
        .removeClass("active")
        .addClass("hidden");
      realty_groups
        .filter(
          ':not([data-slug="zhiluyu-nedvizhimost"], [data-slug="vsyu-nedvizhimost"])'
        )
        .find("input:radio:checked")
        .prop("checked", false);
      input_realty_type.filter(":checked").length ||
        realty_groups
          .filter(":not(.hidden):first")
          .find("input:radio:first")
          .prop("checked", true);
    } else {
      realty_groups.removeClass("hidden");
      input_realty_type.filter(":checked").length ||
        realty_groups
          .filter(":not(.hidden):first")
          .find("input:radio:first")
          .prop("checked", true);
    }

    let checked_realty_type = input_realty_type.filter(":checked");
    if (checked_realty_type.length) {
      checked_realty_type
        .closest(".realty-type-group")
        .addClass("active")
        .siblings()
        .removeClass("active");
    }
  });

  if (false && window.innerWidth < 576 && $(".cell-realty-type").length) {
    param_what.on("change", 'input[name="request_target"]', function () {
      $(".cell-request-target li .realty-type-groups").slideUp();

      $(this).closest("li").find(".realty-type-groups").slideDown();
    });

    param_what.on("click", ".realty-type-group label", function () {
      const realty_groups = param_what.find(".realty-type-group");
      realty_groups.find("input:radio").prop("checked", false);

      const realty_type = $(this).closest("li");
      realty_type
        .find("input:radio:first")
        .prop("checked", true)
        .trigger("change");
    });
  }

  param_what.on(
    "click",
    ".realty-type-group:not(.active) .realty-type-group-name span",
    function () {
      const realty_groups = param_what.find(".realty-type-group");
      const realty_group = $(this).closest(".realty-type-group");
      realty_groups.find("input:radio:checked").prop("checked", false);
      realty_groups.removeClass("active");
      realty_group
        .addClass("active")
        .find("input:radio:first")
        .prop("checked", true)
        .trigger("change");
    }
  );

  param_what.on("change", "input:radio", function (e) {
    if (this.checked) {
      param_what.trigger("dropdown-activated");

      setTimeout(function () {
        get_counts({}, function (data) {
          form_advanced.find(".dropdown-results .apps-count").html(data.html);
          data.count &&
            form_advanced
              .find(".dropdown-results .command-submit")
              .removeClass("disabled");
          data.count ||
            form_advanced
              .find(".dropdown-results .command-submit")
              .addClass("disabled");
        });
      }, 0);
      e.stopPropagation();
    } else {
      e.stopPropagation();
    }
  });

  param_what.on("click", ".button-apply", function () {
    param_what.trigger("change");
  });

  param_what.on("dropdown-deactivated", function () {
    $().add(input_request_target).add(input_realty_type).values(state_prev);
  });

  // ------------------------------------------------------------------------

  // при выборе "только первый этаж" или "только последний этаж" снимаем галочки с остальных этажей
  form_advanced.on(
    "change",
    ".apps-search-form__widget-floor-restrictions input:checkbox",
    function () {
      if (this.checked) {
        if (this.value === "first" || this.value === "last") {
          input_floor.not(this).prop("checked", false);
        } else {
          input_floor
            .filter('[value="first"], [value="last"]')
            .prop("checked", false);
        }
      }
    }
  );

  // ------------------------------------------------------------------------

  // fix opera
  form_advanced.on("input", "input:radio, input:checkbox", function (e) {
    e.stopPropagation();
  });

  form_advanced.on("dropdown-activate", function () {
    var count = parseInt(button_submit.attr("data-count"));
    var message = count
      ? `${translate("есть")} <ins>${count}</ins> <del>${translate(
          declension(count, ["объявление", "объявления", "объявлений"])
        )}</del>`
      : translate("нет объявлений");

    form_advanced.find(".dropdown-results .apps-count").html(message);
    count &&
      form_advanced
        .find(".dropdown-results .command-submit")
        .removeClass("disabled");
    count ||
      form_advanced
        .find(".dropdown-results .command-submit")
        .addClass("disabled");
  });

  // сабмит формы
  form_advanced.on("click", ".command-submit", function () {
    var button = $(this);

    setTimeout(function () {
      if (!wrapper.is(".apps-search-form--changed")) {
        scrollto(window, ".found-count", {
          offset: -20,
        });
        return;
      }
      if (button.is(".disabled")) {
        return;
      }

      do_submit();
    }, 0);
  });

  // сброс формы
  form_advanced.on("click", ".apps-search-form__button-reset", function () {
    var state_curr = assign({}, form_advanced.find(":input[name]").values());
    var state_next = null;

    state_next =
      state_next || is_equal_states(state_curr, state_base)
        ? state_next
        : state_base;
    state_next =
      state_next || is_equal_states(state_curr, state_init)
        ? state_next
        : state_init;

    if (state_next) {
      form_advanced.find(":input[name]").values(state_next);
      form_advanced
        .find(".est-input-clearable")
        .trigger("est-input-clearable-init");
      form_advanced.trigger("change");
    }

    if (
      state_next &&
      !is_equal_states(state_curr, state_next) &&
      !is_equal_states(state_next, state_base)
    ) {
      do_submit();
    }
  });

  // при изменениях в форме запускает пересчет параметров и заново рендерим виджеты
  form_advanced.on(
    "input change autocompleteselect autocompletechange",
    function (event) {
      var target = $(event.target);
      var need_redirect;
      var need_recount;
      var state_curr;

      if (target.is('[name="where_name"]') || target.is('[name="find-name"]')) {
        return;
      }

      state_curr = assign({}, form_advanced.find(":input[name]").values());
      need_recount = !is_equal_states(state_curr, state_prev);
      need_redirect = !is_equal_states(state_curr, state_base);
      need_redirect = need_redirect && event.type !== "input";
      need_redirect =
        need_redirect &&
        (target.is('[name="where"]') ||
          target.is('[name="search"]') ||
          target.is('[name="is_novostrojka"]') ||
          target.is('[name="is_profi"]') ||
          target.is('[name="is_owner"]') ||
          target.is('[name="dogovor"]') ||
          target.is('[name="is_video"]') ||
          target.is('[name="is_foto"]'));

      if (need_recount) {
        if (event.type != "input" || !target.is(":data(ui-autocomplete)")) {
          state_prev = state_curr;
        }

        if (need_redirect) {
          button_submit.removeClass("disabled").trigger("click");
        } else {
          button_submit.addClass("disabled");
          get_counts({}, function (data) {
            button_submit.attr("data-count", data.count);
            data.count || button_submit.addClass("disabled");
            data.count && button_submit.removeClass("disabled");
            form_advanced.find(".dropdown-results .apps-count").html(data.html);
            data.count &&
              form_advanced
                .find(".dropdown-results .command-submit")
                .removeClass("disabled");
            data.count ||
              form_advanced
                .find(".dropdown-results .command-submit")
                .addClass("disabled");
          });
        }
      }

      form_advanced.trigger("render");
    }
  );

  // рендер виджетов
  form_advanced.on("render", function (e) {
    var checked_request_target = input_request_target.filter(
      '[value!=""]:checked'
    );
    var checked_realty_type = input_realty_type.filter(":checked");
    var checked_where = input_where_slug.filter(":checked");
    var checked_floor = input_floor.filter(":checked");
    var request_target = checked_request_target.length
      ? checked_request_target.val()
      : null;
    var realty_type = checked_realty_type.length
      ? checked_realty_type.val()
      : null;
    var target_type = request_target + "_" + realty_type;
    var fields = target_type in tt_info ? tt_info[target_type] : [];
    var with_room = !!$.intersect(fields, [
      "amount-of-rooms",
      "amount-of-bedrooms",
    ]).length;
    var with_free_planning = !!$.intersect(fields, ["is-free-planning"]).length;
    var with_floor = !!$.intersect(fields, ["floor"]).length;

    if (wrapper.attr("data-show-square") === "1") {
      fields.push("square");
    }

    var with_square = !!$.intersect(fields, ["square"]).length;
    var with_square_land = !!$.intersect(fields, [
      "square-land-ar",
      "square-land-ga",
    ]).length;
    var with_square_living = !!$.intersect(fields, ["living-square"]).length;
    var with_c_type = !!$.intersect(fields, ["commercial-type"]).length;
    var square_unit = "m2";
    var txt_request_target;
    var txt_realty_type;

    param_room.find(":input").prop("disabled", !with_room);
    param_floor.find(":input").prop("disabled", !with_floor);
    param_floor_restr.find(":input").prop("disabled", !with_floor);
    param_square.find(":input").prop("disabled", !with_square);
    param_square_land.find(":input").prop("disabled", !with_square_land);
    param_square_living.find(":input").prop("disabled", !with_square_living);
    param_c_type.find(":input").prop("disabled", !with_c_type);

    square_unit =
      -1 !== $.inArray("cost-for-meter", fields) ? "m2" : square_unit;
    square_unit = -1 !== $.inArray("cost-for-ar", fields) ? "ar" : square_unit;
    square_unit = -1 !== $.inArray("cost-for-ga", fields) ? "ga" : square_unit;

    wrapper.attr("data-fields", fields.join(" "));
    wrapper.attr("data-request-target", request_target || "");
    wrapper.attr("data-realty-type", realty_type || "");
    wrapper.attr("data-square-unit", square_unit);

    toggler_currency.text(param_price.find("input:checked").next().text());
    toggler_where.attr("title", checked_where.next("span").text());
    toggler_where.text(checked_where.next("span").text());
    txt_request_target = translate("вся");
    txt_request_target = checked_request_target.length
      ? checked_request_target.next("label").text()
      : txt_request_target;
    txt_request_target =
      txt_request_target.toLowerCase() == "сдают посуточно"
        ? translate("посуточно")
        : txt_request_target;
    txt_realty_type = translate("недвижимость");
    txt_realty_type = checked_request_target.length
      ? translate("всю недвижимость")
      : txt_realty_type;
    txt_realty_type =
      checked_request_target.length && checked_realty_type.length
        ? checked_realty_type.next("label").text()
        : txt_realty_type;
    txt_realty_type = ("" + realty_type).match(/^zemelnyj-uchastok/i)
      ? translate("земельный участок") + " " + txt_realty_type
      : txt_realty_type;
    toggler_what.find('[data-name="target"]').text(txt_request_target);
    toggler_what.find('[data-name="type"]').text(txt_realty_type);
    toggler_what.attr(
      "title",
      toggler_what
        .text()
        .toLowerCase()
        .replace(/\s+/g, " ")
        .replace(/^\s+|\s+$/g, "")
    );

    if (checked_realty_type.length > 0) {
      checked_realty_type
        .closest(".realty-type-group")
        .addClass("active")
        .siblings()
        .removeClass("active");
    } else {
      input_realty_type.closest(".realty-type-group").removeClass("active");
      input_price_for.val([""]);
    }

    checked_floor.length &&
      param_floor
        .find('input[type="text"], input[type="number"]')
        .prop("disabled", true)
        .val([]);

    rajons_show_selected();
    rajon_change_button_caption();

    const is_homepage = "homepage" === (sf_module || "homepage");
    const is_commercial_main =
      "commercialMain" === (sf_module || "commercialMain");
    const state_curr = assign({}, form_advanced.find(":input[name]").values());
    const is_diff_with_base = !is_equal_states(state_curr, state_base);
    const is_changed_style =
      is_homepage || is_commercial_main || is_diff_with_base;

    if (typeof wrapper.attr("data-women-search") == "undefined") {
      wrapper.toggleClass("apps-search-form--changed", is_changed_style);
    }
  });

  // ------------------------------------------------------------------------
  // сохраненные поиск

  $("#find-saver-toggle").on("dropdown-activated", function () {
    var txt_where = input_where_text.val();
    var txt_target = form_advanced
      .find('span[data-name="target"]')
      .text()
      .toLowerCase();
    var txt_type = form_advanced
      .find('span[data-name="type"]')
      .text()
      .toLowerCase();
    var input = $("#find-name");
    var findName = txt_where + ", " + txt_target + " " + txt_type;

    //input.val(findName.charAt(0).toUpperCase() + findName.substr(1).toLowerCase());
    input.val(findName);
  });

  $("#find-saver-button").on("click", function () {
    var params = get_params({
      "find-name": null,
      "where_name": null,
    });

    if (params.currency && params.currency == "UAH") {
      delete params.currency;
    }

    let button = $(this);
    let input = $("#find-name");
    let url = button.data("url");
    let url2 = wrapper.attr("data-action");
    let loadingClassName = "loading";
    let param = get_params(
      {
        sf_format: "json",
        where_name: null,
      },
      params || {}
    );

    if (["homepage", "commercial"].indexOf(sf_module || "") !== -1) {
      if (param.request_target && param.realty_type) {
        url2 += param.request_target + "-" + param.realty_type + "/";
        if (typeof param.lj == "string" && param.lj > "") {
          url2 += "rajon-" + param.lj + "/";
          delete param["lj"];
        }
      }
      delete param["request_target"];
      delete param["realty_type"];
    }

    let data = {
      sf_format: "jsonp",
      data: get_params({
        sf_format: "json",
      }),
      name: input.val(),
      url: url2 + "?" + $.param(params),
    };

    if (!button.hasClass(loadingClassName)) {
      $.ajax({
        url: url,
        dataType: "jsonp",
        data: data,
        beforeSend: function () {
          button.addClass(loadingClassName);
          input.addClass(loadingClassName);
        },
        complete: function () {},
        error: function (data) {},
        success: function (data) {
          if (data.code != 0) {
            alert("Такой поиск уже сохранен.");
          } else {
            RedwawSavedSearchDropdown();
          }
          button.removeClass(loadingClassName);
          input.removeClass(loadingClassName);
          $(".btn-close").click();
        },
      });
    }
  });

  // обновление полигона
  jMap.on("est-map-change", function () {
    if (jMap.attr("data-polygonID")) {
      widget_polygon_id.val(jMap.attr("data-polygonID"));
    }
  });

  FindNameLinkHover();

  form_advanced.trigger("render");

  if (false && window.innerWidth < 576 && $(".cell-realty-type").length) {
    let realtyTypeContent = $(".cell-realty-type").hide().html();

    $(".cell-request-target li").each(function (i, el) {
      $(el).append(realtyTypeContent);
    });
  }
};
