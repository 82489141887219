import { language } from "./language";

const is_ua = language === "ua";

let translations = {};

let translate = function (string) {
  return translate.get(string);
};

translate.getSrc = function (string) {
  return string;
};

translate.getRes = function (string) {
  return string in translations ? translations[string] : string;
};

translate.get = function (string) {
  return is_ua ? translate.getRes(string) : translate.getSrc(string);
};

translate.add = function (ru, ua) {
  if (ua) {
    translations[ru] = ua;
  } else {
    for (let i in ru) {
      if (ru.hasOwnProperty(i)) {
        translate.add(i, ru[i]);
      }
    }
  }
};

export default translate;
